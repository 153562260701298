import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from '../models/User';
import { RoleUpdateModel } from '../models/RoleUpdateModel';

@Injectable()
export class UserService {
  constructor(private readonly http: HttpClient) {
  }

  /**
   * get all users
   */
  getUsers(): Observable<Array<User>> {
    return this.http.get<Array<User>>(environment.apiBaseUrl + 'users').pipe(shareReplay(), catchError(this.handleError));
  }

  /*
  *update user role.
  */
  updateRole(user: User): Observable<User> {
    return this.http.put<User>(environment.apiBaseUrl + 'userroles', RoleUpdateModel.createObjFromUser(user))
    .pipe(catchError(this.handleError));
  }

  private handleError(err: HttpErrorResponse | any) {
    console.error('An error occured', err);
    return throwError(err.message || err);
  }
}
