import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Site } from '../../sites/models/Site';

@Injectable()
export class SiteService {
  constructor(private readonly http: HttpClient) {
  }

  getSites(): Observable<Array<Site>> {
    return this.http.get<Array<Site>>(environment.apiBaseUrl + 'cement/sites').pipe(shareReplay(), catchError(this.handleError));
  }


  private handleError(err: HttpErrorResponse | any) {
    console.error('An error occured', err);
    return throwError(err.message || err);
  }
}
